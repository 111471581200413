import React from 'react'
import './clients.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Clients = () => {
    // Slick Slider settings
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="container-fluid section-clientele">
                <div className="container">
                    <div className="row">
                        <p className="heading text-center">OUR CLIENTELE</p>
                    </div>
                    <Slider {...settings}>
                        <div className="px-2">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/img/home/clients/Partner_Logos-01.png'}
                                alt="Partner 1"
                                className="w-100"
                            />
                        </div>
                        <div className="px-2">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/img/home/clients/Partner_Logos-02.png'}
                                alt="Partner 2"
                                className="w-100"
                            />
                        </div>
                        <div className="px-2">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/img/home/clients/Partner_Logos-03.png'}
                                alt="Partner 3"
                                className="w-100"
                            />
                        </div>
                        <div className="px-2">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/img/home/clients/Partner_Logos-04.png'}
                                alt="Partner 4"
                                className="w-100"
                            />
                        </div>
                        <div className="px-2">
                            <img
                                src={process.env.PUBLIC_URL + '/assets/img/home/clients/Partner_Logos-05.png'}
                                alt="Partner 5"
                                className="w-100"
                            />
                        </div>
                    </Slider>
                </div>
            </div>
        </>
    );
};
export default Clients
