import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonials.css';

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  };

  return (
    <div className="container-fluid sec-testimonials">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto text-center">
            <p className="heading mb-0">What Clients Say</p>
            <p className="para mb-0">
              We place huge value on strong relationships and have seen the benefit they bring to our business. Customer feedback is vital in helping us to get it right.
            </p>
          </div>
        </div>
        <Slider {...settings}>
          {[
            {
              name: "Raghu",
              // image: "/assets/img/home/testimonials/client-one.jpg",
              text: "The Scale Story’s ads have really boosted our visibility. We’re getting more inquiries for data recovery services, and their screens are clear and impactful. Great results all around!",
              rating: 5,
              title: "CTO",
              company:"360 Data Recovery"
            },
            {
              name: "Ajay",
              // image: "/assets/img/home/testimonials/client-one.jpg",
              text: "Advertising with The Scale Story has been fantastic for us. Their HD screens highlight our nut mixes beautifully and have definitely increased our foot traffic and sales. Very pleased with the outcome!",
              rating: 4,
              title: "Founder",
              company:"Ripe Spicy"
            },
            {
              name: "Shruthi",
              // image: "/assets/img/home/testimonials/client-one.jpg",
              text: "Our partnership with The Scale Story has been a real boost. Their displays have effectively showcased our branding and digital services, leading to increased client engagement. We’re very satisfied!",
              rating: 5,
              title: "Operations",
              company:"Phoenix Branding"
            },
            {
              name: "Surya",
              // image: "/assets/img/home/testimonials/client-one.jpg",
              text: "The Scale Story’s screens have done wonders for promoting our safety training services. We’ve seen a rise in inquiries and visibility. Their advertising solutions are really effective.",
              rating: 5,
              title: "Director",
              company:"Safety Zest"
            },
            {
              name: "Narendra",
              // image: "/assets/img/home/testimonials/client-one.jpg",
              text: "Advertising with The Scale Story has been great for us. Their screens gave our land projects excellent exposure, and we’ve noticed more interest and inquiries. It’s been a successful collaboration!",
              rating: 5,
              title: "Realtor",
              company:"HM Realty"
            }
          ].map((testimonial, index) => (
            <div key={index} className="px-2 pt-4">
              <div className="card text-center shadow rounded-4 h-100">
                <div className="card-body">
                  {/* <div>
                    <img
                      src={process.env.PUBLIC_URL + testimonial.image}
                      alt={`Testimonial ${index}`}
                      className="mb-2"
                    />
                  </div> */}
                  <p className="mb-2">{testimonial.text}</p>
                  <div>
                    {Array.from({ length: testimonial.rating }).map((_, i) => (
                      <i key={i} className="fa fa-star ms-2 mb-2" aria-hidden="true"></i>
                    ))}
                  </div>
                  <p className="client-name mb-0">{testimonial.name}</p>
                  <p className="client-title mb-0">{testimonial.title}</p>
                  <p className="client-company mb-0">{testimonial.company}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
