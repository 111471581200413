import React from 'react'
import './advariety.css'

const Advariety = () => {
    return (
        <>
            <div className="container-fluid section-variety bg8">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className='heading mb-3'>Discover our exclusive service bundles below</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 my-3">
                        <div className="p-3 variety-img-one">
                                <p className='variety-heading mb-2'>OOH Advertising</p>
                                <p className='variety-para'>"Get noticed with our state-of-the-art TVs in city hotspots, looping your captivating 20-second ad for just Rs. 0.66 per spot! The ultimate choice for savvy advertisers."</p>
                            </div>
                        </div>
                        <div className="col-md-6 my-3">
                            <div className="p-3 variety-img-two">
                                <p className='variety-heading mb-2'>Interactive Advertising</p>
                                <p className='variety-para'>Let's craft interactive ads that captivate your audience and drive action. Imagine QR codes leading directly to your website or social media profiles, or engaging games that highlight your brand and products.</p>
                            </div>
                        </div>
                        <div className="col-md-6 my-3">
                        <div className="p-3 variety-img-three">
                                <p className='variety-heading mb-2'>Consultation</p>
                                <p className='variety-para'>Embrace Collaboration! We team up for advertising success, partnering closely with you to understand your business goals. Crafting a bespoke plan, we ensure it aligns perfectly with your needs and budget.</p>
                            </div>
                        </div>
                        <div className="col-md-6 my-3">
                            <div className="p-3 variety-img-four">
                                <p className='variety-heading mb-2'>Targeted Advertising</p>
                                <p className='variety-para'>We target your ads precisely for maximum impact within your budget. With The Scale Story, make every advertising dollar count. Contact us to powerfully tell your brand story, no matter your business size.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Advariety
