import React from 'react'
import About from '../../components/about/hero/About'
import GoogleMap from '../../components/about/googlemap/GoogleMap'

const AboutPage = () => {
  return (
    <>
      <About/>
      <GoogleMap/>
    </>
  )
}

export default AboutPage
