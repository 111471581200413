import React from 'react'

const PlaylistOne = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12" style={{height:'600px', marginTop:'100px', color:'#fda446'}}>
                <h1>Resturant</h1>
                <iframe style={{width:'100%', height:'500px', border:'2px solid #fda446'}} src="https://www.youtube.com/embed/videoseries?list=PLa9NMvQUMD5cAbfks_vdayAbzxXjyG4ly" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PlaylistOne
