import React from 'react'
import './workingstory.css'

const WorkingStory = () => {
  return (
    <>
    <div className="container-fluid section-working-story">
        <div className="container">
            <div className="row">
                <div className="col-md-10 text-center mx-auto mb-4">
                    <p className='heading-one mb-1'>OUR STORY-TELLING SERVICES</p>
                    <p className='sub-heading-one'>Cost-Effective Advertising Solutions</p>
                    <p className='heading-two mb-1'>HOW IT WORKS?</p>
                    <p className='sub-heading-two'>At The Scale Story, we make advertising easy and hassle-free. </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex align-items-stretch text-center mb-3">
                    <div className="card bg2">
                        <div className="card-body">
                            <img src="assets/img/home/ideasIcons/Choose_Your_Plan.png" alt="" className='icon' />
                            <p className='story-heading'>Choose your plan</p>
                            <p className="story-para">We offer flexible plans to fit your business needs and budget. Choose the plan that best suits your advertising goals.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex align-items-stretch text-center mb-3">
                    <div className="card bg2">
                        <div className="card-body">
                            <img src="assets/img/home/ideasIcons/Create_your_ad.png" alt="" className='icon' />
                            <p className='story-heading'>Create your ad</p>
                            <p className="story-para">You can either create your own TV commercial or we can recommend you a creative agency to create your 10sec TV commercial. Still our team of expert content creators will work with you to guide you create a compelling ad that tells your brand story in an engaging way.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex align-items-stretch text-center mb-3">
                    <div className="card bg2">
                        <div className="card-body">
                            <img src="assets/img/home/ideasIcons/Launch _your_campaign.png" alt="" className='icon' />
                            <p className='story-heading'>Launch your campaign</p>
                            <p className="story-para">Once your ad is ready, we'll launch it across our network of machines. You can sit back and watch as your brand gets the exposure it deserves.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div >
</>
  )
}

export default WorkingStory
