import React from 'react'
import './chooseus.css'

const ChooseUs = () => {
  return (
    <>
      <div className="container-fluid section-chooseus">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center">
              <p className='head'>WHY CHOOSE US?</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Brand_Storytelling.png" alt="Choose Us One" />
              <div className='para'>
                <p>Brand Storytelling</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Competitive_Edge.png" alt="Choose Us Two" />
              <div className='para'>
                <p>Competitive Edge</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Cost_Effective.png" alt="Choose Us Three" />
              <div className='para'>
                <p>Cost Effective</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Dynamic_Content.png" alt="Choose Us Four" />
              <div className='para'>
                <p>Dynamic Content</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Engaged_Audience.png" alt="Choose Us Five" />
              <div className='para'>
                <p>Engaged Audience</p>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Flexibility.png" alt="Choose Us Six" />
              <div className='para'>
                <p>Flexibility</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_High_Impact.png" alt="Choose Us Seven" />
              <div className='para'>
                <p>High Impact</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_High_Visibility.png" alt="Choose Us Eight" />
              <div className='para'>
                <p>High Visibility</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Innovative_Advertising.png" alt="Choose Us Nine" />
              <div className='para'>
                <p>Innovative Advertising</p>
              </div>
            </div>
            <div className="col-md-2 mx-auto text-center">
              <img src="assets/img/home/contactUs/Icons_Wide_Reach.png" alt="Choose Us Ten" />
              <div className='para'>
                <p>Wide Reach</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseUs
