import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './form.css'

function Form() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_0yhe653', 'template_7a8cfqm', form.current, {
                publicKey: 'RbyMmO2rGumXe_4mU',
            })
            .then(
                (result) => {
                  console.log('SUCCESS!', result.text);
                  alert('Your form has been submitted successfully!');
                  form.current.reset(); // Reset the form after successful submission
                },
                (error) => {
                  console.log('FAILED...', error.text);
                  alert('Failed to submit the form. Please try again.');
                },
              );
          };
    return (
        <>
            <div className="container-fluid section-contact-form bg3">
                <div className="container">
                    <div className="row">
                        <div className="row">
                            <div className="col-md-8 text-center mx-auto mb-4">
                                <p className='heading'>Always At Your Service</p>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12 mx-auto">
                            <div className="card border-0 shadow rounded-4">
                                <div className="row g-0 align-items-center">
                                    <div className="col-md-5">
                                        <iframe className="rounded-start-4" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15554.418169870238!2d77.5828281!3d12.9331194!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x65b29aee662e2cd7%3A0x99b1d45be4c0ed9e!2sThe%20Scale%20Story!5e0!3m2!1sen!2sin!4v1714806416089!5m2!1sen!2sin" style={{ width: '100%', height: '350px' }}></iframe>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="card-body">
                                            <h5 className="card-title mb-4">Reach Out To Us</h5>
                                            <form ref={form} onSubmit={sendEmail}>
                                            <div className="row g-3 mb-3">
                                                <div className="col">
                                                    <input type="text" className="form-control" placeholder="First name" aria-label="First name"  name="first_name" required/>
                                                </div>
                                                <div className="col">
                                                    <input type="text" className="form-control" placeholder="Last name" aria-label="Last name" name="last_name" required/>
                                                </div>
                                            </div>
                                            <div className="row g-3 mb-3">
                                                <div className="col">
                                                    <input type="email" className="form-control" placeholder="Email" aria-label="Email" name="email" required/>
                                                </div>
                                                <div className="col">
                                                    <input type="phone" className="form-control" placeholder="Phone Number" aria-label="Phone Number" name="phone" required/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div>
                                                    <textarea className="form-control" placeholder="Message" id="exampleFormControlTextarea1" rows="3" name="message" required></textarea>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2 mx-auto text-center" style={{width:'100%', maxWidth:'120px'}}>
                                                    <button type="submit" className="btn btn-dark form-control" value="Submit">Sumit</button>
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Form;
