import React, { useState, useEffect, useRef } from 'react';
import './header.css';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    const location = useLocation();
    const navbarCollapseRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (navbarCollapseRef.current && !navbarCollapseRef.current.contains(event.target)) {
                closeNavbar();
            }
        };

        document.body.addEventListener('click', handleOutsideClick);

        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const closeNavbar = () => {
        const navbarToggler = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');

        if (navbarToggler && navbarCollapse && navbarCollapse.classList.contains('show')) {
            navbarToggler.click();
        }
    };

    useEffect(() => {
        closeNavbar();
    }, [location.pathname]);

    return (
        <>
            <nav className={`navbar navbar-expand-md position-fixed w-100 z-2 ${scrolled ? 'scrolled' : ''}`}>
                <div className="container px-0 main-menu">
                   <a href="#"> <img src="assets/img/common/headerLogo.png" alt="The Scale Story" className='logo ps-3'/></a>
                    <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={navbarCollapseRef}>
                        <ul className="navbar-nav ms-auto gap-md-5 mb-2 mb-lg-0 bg nav-font main-menu">
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/'}>HOME</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/about'}>ABOUT</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/service'}>SERVICES</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" to={'/blog'}>BLOG</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to={'/contact'}>CONTACT US</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
