import React from 'react'
import './about.css'
const About = () => {
  return (
    <>
    {/* <div className='container-fluid pb-5'>

    </div> */}
    <div className="container-fluid about-bg-img text-md-left">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <p className='heading-one'>WE CAN HELP</p>
                    <span className='heading-two'>BUSINESSES REACH</span>
                    <p className='heading-three'>masses</p>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5 sec-about">
        <div className="container">
            <div className="row">
                <div className="col-md-10 text-center mx-auto">
                    <p>At The Scale Story, we are a team of experienced professionals who are passionate about oering aordable and sustainable advertising
                        solutions for businesses. We believe in creating value-driven brand stories and promoting them through our innovative TV machines,
                        which not only save costs but also help save paper.</p>
                </div>
            </div>
        </div>
    </div>
    {/*  */}
    <div className="container-fluid sec-about-scopes">
        <div className="row">
            <div className="col-md-4 bg5 px-5 text-center pb-2">
                <div className='d-flex justify-content-center pt-3 pb-1 pe-5'>
                    <img src={process.env.PUBLIC_URL + '/assets/img/about/vision.png'} alt="VISION" />
                    <p className='heading ms-2'>VISION</p>
                </div>
                <p className='para px-2'>Our vision is to redefine the landscape of outdoor advertising by becoming the leading provider of affordable, high-impact display solutions. We aspire to empower brands of all sizes with innovative advertising tools, reaching audiences everywhere and making advertising more accessible and effective for the future.</p>
            </div>
            <div className="col-md-4 bg2 px-5 text-center pb-2">
                <div className='d-flex justify-content-center pt-3 pb-1 pe-5'>
                    <img src={process.env.PUBLIC_URL + '/assets/img/about/Mission.png'} alt="MISSION" />
                    <p className='heading ms-2'>MISSION</p>
                </div>
                <p className='para px-2'>At The Scale Story, our mission is to transform outdoor advertising with cutting-edge display technology. We aim to make advertising accessible and impactful by installing 2000 high-definition displays in the next three years, delivering unparalleled visibility and value to our clients.</p>
            </div>
            <div className="col-md-4 bg5 px-5 text-center pb-2">
                <div className='d-flex justify-content-center pt-3 pb-1 pe-5'>
                    <img src={process.env.PUBLIC_URL + '/assets/img/about/Team.png'} alt="TEAM" />
                    <p className='heading ms-2'>TEAM</p>
                </div>
                <p className='para px-2'>We are lucky to have a team of highly
                    qualified and experienced employees that
                    have committed their lives to serving our
                    customers</p>
            </div>
        </div>
    </div>
   
    <div className="container-fluid bg3 py-5 sec-about-area">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <p className='mb-0'>working towards scaling up to 2000+ screens</p>
                </div>
            </div>
        </div>
    </div>
    
    <div className="container-fluid py-5 d-none d-md-block sec-about-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-4"></div>
                <div className="col-md-4 z-1">
                    <p className='mb-0 text-center'>Get featured on</p>
                </div>
                <div className="col-md-4 text-center">
                    <img src={process.env.PUBLIC_URL + '/assets/img/about/feature.png'} alt="" />
                </div>
            </div>
        </div>
    </div>
    {/* Mobile Device */}
    <div className="container-fluid py-5 d-md-none d-block sec-about-area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col">
                    <p className='mb-0 '>Get featured on</p>
                </div>
                <div className="col text-end">
                    <img src={process.env.PUBLIC_URL + '/assets/img/feature.png'} alt="" />
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default About
