import React from 'react'
import './pricingAdList.css'

const PricingAdList = () => {
    return (
        <>
            <div className="container-fluid section-pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto text-center">
                            <p>Price Comparision of The Scale Story Vs Others</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-10 mx-auto table-responsive">
                            <table className="table mb-0 table-secondary table-striped table-hover text-center">
                                <thead className='table-dark'>
                                    <tr>
                                        <th scope="col">Advertising Method</th>
                                        <th scope="col">Cost per Ad</th>
                                        <th scope="col">Repetitions</th>
                                        <th scope="col">Total Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">The Scale Story</th>
                                        <td>Rs.0.50</td>
                                        <td>1,50,000/month</td>
                                        <td>Rs.75,000/month</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Radio Jingles</th>
                                        <td>Rs.500.00</td>
                                        <td>100 Slots/day</td>
                                        <td>Rs.50,000/day</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Flyer Distribution</th>
                                        <td>Rs.2.50</td>
                                        <td>10,000 Flyers/day</td>
                                        <td>Rs.25,000/day</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Hoarding</th>
                                        <td >Rs.20,000.00+</td>
                                        <td>30 Days</td>
                                        <td>Rs.6,00,000/month</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">TV Channel Ad</th  >
                                        <td>Rs.200.00+</td>
                                        <td>100 Slots/day</td>
                                        <td>Rs.20,000/day</td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingAdList
