import React from 'react'
import Hero from '../../components/home/hero/Hero'
import InfoCards from '../../components/home/infocards/InfoCards'
import About from '../../components/home/about/About'
import Scopes from '../../components/home/scopes/Scopes'
import Clients from '../../components/home/clients/Clients'
import ChooseUs from '../../components/home/whychooseus/ChooseUs'
import WorkingStory from '../../components/home/howitworks/WorkingStory'
import ConsultantForm from '../../components/home/consultantform/ConsultantForm'
import Testimonials from '../../components/home/testimonials/Testimonials'
import Faqs from '../../components/home/faqs/Faqs'

const Home = () => {
    return (
        <>
        <Hero/>
        <InfoCards/>
        <About/>
        <Scopes/>
        <Clients/>
        <ChooseUs/>
        <WorkingStory/>
        <ConsultantForm/>
        <Testimonials/>
        <Faqs/>
        </>
    )
}

export default Home
