import React from 'react'
import './hero.css'

const Hero = () => {
    return (
        <>
            <div className="container-fluid contact-bg-img  text-md-left">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <p className='heading-one'>NEED US?</p>
                            <span className='heading-two'>WE WILL BE IN</span>
                            <p className='heading-three'>touch</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero
