import React from 'react'
import PlaylistOne from '../../components/playlist/PlaylistOne'

const PlaylistVideo = () => {
  return (
    <>
      <PlaylistOne/>
    </>
  )
}

export default PlaylistVideo
