import React from 'react'
import './infocards.css'
import { NavLink } from 'react-router-dom'

const InfoCards = () => {
  return (
    <>
    <div className="container-fluid section-detail">

        <div className="row text-center section-detail-body">
            <div className="col-md-3 bg1" >
               <div className='moving-element'>
               <p className='detail-one mb-0 pt-5'>Running Early Bird Offer</p>
               <p className='detail-two pb-5 mb-0 pt-3'> Upto 30% Off</p>
               </div>
            </div>
            <div className="col-md-3 bg2">
                <p className='detail-one mb-0 pt-5'>Our Presence in Bangalore</p>
                <p className='detail-two pb-5 mb-0 pt-3'>50 Locations</p>
            </div>
            <div className="col-md-3 bg3">
            <div className='moving-element'>
                <p className='detail-one mb-0 pt-5'>Our Ads have Reached</p>
                <p className='detail-two pb-5 mb-0 pt-3'>3 crore and counting</p>
                </div>
            </div>
            <div className="col-md-3 bg4">
                    <p className='detail-one mb-0 pt-5'>Book your ad slot Now</p>
                <div className="pb-5 mb-0 pt-3">
                <NavLink to="/contact" type="button" className="btn-info">Book Now</NavLink>
                </div>
            </div>
        </div>
    </div>

</>
  )
}

export default InfoCards
