import './consultantform.css'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ConsultantForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_bybzujb', 'template_tl5p33m', form.current, {
                publicKey: 'ZOscRe989Jr6rkkC7',
            })
            .then(
                (result) => {
                  console.log('SUCCESS!', result.text);
                  alert('Your form has been submitted successfully!');
                  form.current.reset();
                },
                (error) => {
                  console.log('FAILED...', error.text);
                  alert('Failed to submit the form. Please try again.');
                },
              );
          };
  return (
    <>
    <div className="container-fluid section-consultant-form">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <p className='heading'>REQUEST A CALL FROM OUR CONSULTANT</p>
                    <span className='para'>we would love to hear your plans</span>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6 px-5 mt-3">
                        <form  ref={form} onSubmit={sendEmail}>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="text" className="form-control" placeholder="First name" aria-label="First name" name="first_name" required/>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <input type="text" className="form-control" placeholder="Last name" aria-label="Last name" name="last_name" required/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <input type="text" className="form-control" placeholder="Email" aria-label="Email" name="email" required/>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <input type="number" className="form-control" placeholder="Phone" aria-label="Phone" name="phone" required/>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-warning mt-3">Submit</button>
                        </form>
                    </div>
                    <div className="col-md-6 pt-4 px-5">
                        <img src={process.env.PUBLIC_URL + '/assets/img/home/consultantform/consultant.png'} alt="Consultant" className='w-100' />
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default ConsultantForm
