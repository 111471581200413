import React from 'react'
import './faqs.css'

const Faqs = () => {
  return (
    <>
    <div className="container-fluid section-faqs">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto text-center">
            <p className='accordion-title'>FREQUENTLY ASKED QUESTION</p>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-6">
            <div className="accordion" id="accordionScaleStory">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  What is The Scale Story?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory">
                  <div className="accordion-body">
                    <p className="mb-0">The Scale Story is an innovative advertising platform that offers businesses a cost-effective way to advertise their products or services through short TV commercials displayed on digital weighing scales installed in various public locations.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  How does The Scale Story work?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory">
                  <div className="accordion-body">
                    <p className="mb-0">The Scale Story places digital weighing scales in high-traffic public areas, such as restaurants. Businesses can advertise on these scales by purchasing short TV commercials that play repeatedly throughout the day. The cost of advertising is very affordable, making it accessible for any sized businesses. Be it SME or a huge coporation.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  How many locations does The Scale Story operate in?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory">
                  <div className="accordion-body">
                    <p className="mb-0">The Scale Story is currently operating in 50 locations in Bengaluru, India. However, the company aims to expand its operations to other cities in the future.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  How long are the TV commercials on The Scale Story?
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory">
                  <div className="accordion-body">
                    <p className="mb-0">The TV commercials on The Scale Story are 10 seconds long, which is enough time to showcase your product or service in a creative and engaging way.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="accordion" id="accordionScaleStory1">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">What is the cost of advertising on The Scale Story?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory1">
                  <div className="accordion-body">
                    <p className="mb-0">The cost of advertising on The Scale Story is very affordable, with a cost per ad of just Rs. 0.50 and a charge of Rs. 1,250 for 100 repeatations of ads on each TV installed.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">How can I create a TV commercial for The Scale Story?
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory1">
                  <div className="accordion-body">
                    <p className="mb-0">You can either create your own TV commercial or hire a professional ad agency to create one for you. Once your commercial is ready, you can share it to The Scale Story team, and the team will take care of the rest.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">How effective is advertising on The Scale Story?</button>
                </h2>
                <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory1">
                  <div className="accordion-body">
                    <p className="mb-0">Advertising on The Scale Story can be very effective, especially for businesses that want to target a specific audience. The digital weighing scales are installed in high-traffic public areas, which means that your commercial will be seen by a large number of people every day.</p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">How effective is advertising on The Scale Story?</button>
                </h2>
                <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionScaleStory1">
                  <div className="accordion-body">
                    <p className="mb-0">Yes, advertising on The Scale Story is environmentally friendly as it eliminates the need for printing flyers or posters, which reduces paper waste and helps the environment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
  )
}

export default Faqs
