import React from 'react'
import './scopes.css'

const Scopes = () => {
    return (
        <>
            <div className="container-fluid section-scopes">
                <div className="row ">
                    <div className="col-md-6 bg2 py-3 text-center">
                        <img src={process.env.PUBLIC_URL + '/assets/img/home/scopes/scope.png'} alt="" />
                    </div>
                    <div className="col-md-6 bg4 py-3 px-5 ">
                        <p className='head mb-0'>Our Mission</p>
                        <p className='para'>At The Scale Story, our mission is to transform outdoor advertising with cutting-edge display technology. We aim to make advertising accessible and impactful by installing 2000 high-definition displays in the next three years, delivering unparalleled visibility and value to our clients.</p>
                        <p className='head mb-0'>Our Vision</p>
                        <p className='para'>Our vision is to redefine the landscape of outdoor advertising by becoming the leading provider of affordable, high-impact display solutions. We aspire to empower brands of all sizes with innovative advertising tools, reaching audiences everywhere and making advertising more accessible and effective for the future.</p>
                        <p className='head mb-0'>Our Team</p>
                        <p className='para'>We are lucky to have a team of highly qualiﬁed and experienced
                            employees that have committed their lives to serving our customers.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scopes
