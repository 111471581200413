import React from 'react'
import Hero from '../../components/contactus/hero/Hero'
import Form from '../../components/contactus/contactform/Form'
import Address from '../../components/contactus/contactaddress/Address'

const ContactUs = () => {
  return (
    <>
      <Hero/>
      <Form/>
      <Address/>
    </>
  )
}

export default ContactUs
