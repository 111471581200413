import React from 'react'
import './hero.css'

const Hero = () => {
    return (
        <>
            <div className="container-fluid service-bg-img text-md-left">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <p className='heading-one'>AMPLIFY YOUR</p>
                            <span className='heading-two'>BUSINESSES REACH</span>
                            <p className='heading-three'>with us</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero
