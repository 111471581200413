import React from 'react'
import Hero from '../../components/services/hero/Hero'
import Ecopreneur from '../../components/services/Ecopreneur/Ecopreneur'
import Advariety from '../../components/services/Advariety/Advariety'
import Proposition from '../../components/services/Proposition/Proposition'
import PricingAdList from '../../components/services/PricingAdList/PricingAdList'

const Services = () => {
  return (
    <>
      <Hero/>
      <Ecopreneur/>
      <Advariety/>
      <Proposition/>
      <PricingAdList/>
    </>
  )
}

export default Services
