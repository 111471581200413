import React from 'react'
import './ecopreneur.css'

const Ecopreneur = () => {
    return (
        <>
            <div className="container-fluid section-ecopreneur">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <img src="assets/img/service/ecopreneur.jpeg" alt="Ecopreneur Image" className='w-100' />
                        </div>
                        <div className="col-md-7 mx-auto">
                            <p className='heading'>The Scale Story: Bridging Business and Eco-Conscious Advertising</p>
                            <p className='para'>The Scale Story was born from the collective vision of three friends driven by entrepreneurship and environmental stewardship. Co-founders D S Santosh Setty, K S Sangharsh, and P R Babu fused their vast expertise in business operations, branding, marketing, and field operations to create a unique platform offering affordable and eco-friendly advertising solutions. Specializing in tailored services for businesses of all sizes, The Scale Story aims to empower clients to effectively connect with their target audience and authentically convey their brand narrative.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ecopreneur
