import React from 'react'
import './proposition.css'

var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span className="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 100 - Math.random() * 50;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
    }

    setTimeout(function() {
    that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 2px solid #fff}";
    document.body.appendChild(css);
};

const Proposition = () => {
    return (
        <>
            <div className="container-fluid section-proposition">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center">
                            <p className="heading">Value Proposition For your <a href="" className="heading typewrite" data-period="2000" data-type='["Branding",  "Marketing",  "Advertising"]'>
                                    <span className="wrap"></span>
                                </a>
                            </p>
                        </div>
                        <div className="row text-center">
                            <div className="col-md-12">
                                <p className="para">The Scale Story’s value proposition for advertisers is based on the ability to tell a brand’s story in a cost-effective, eco-friendly, and impactful way.
                                    The traditional advertising methods such as radio jingles, flyer distribution, and hoardings are either not cost-effective or not impactful enough. With The Scale Story, the cost per ad is only Rs.0.50, which is much lower compared to traditional advertising methods. The high repetition of ads in each TV (100 repetitions) placed in high-traffic locations across Bengaluru ensures maximum visibility for the brand.Another advantage of The Scale Story is that it is an eco-friendly advertising solution. The elimination of paper printing, it minimizes the brand’s environmental footprint, which is increasingly important to consumers who are becoming more environmentally conscious.</p>
                                <p className="para">Furthermore, The Scale Story is a purpose-driven company, and it focuses on brand storytelling rather than just using the word “ads.” By creating a powerful and engaging brand story that resonates with the target audience, it creates an emotional connection that leaves a lasting impact.

                                    With The Scale Story’s cost-effective, eco-friendly, and impactful approach to brand storytelling, advertisers can enjoy a significant return on their investment. They can tell their brand’s story in a way that builds an emotional connection with their target audience and ensures maximum exposure, all while minimizing their environmental impact. Advertisers can leverage The Scale Story’s unique value proposition to differentiate themselves from their competitors and stand out in their industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Proposition
