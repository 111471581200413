import React from 'react'
import './address.css'

const Address = () => {
  return (
    <>
       <div className="container-fluid section-address">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mx-auto text-center">
                            <p className='heading'>Contact Us</p>
                            <p className='para'>If you have any questions about these terms please schedule a consultation call to discuss details of your requirement.</p>
                        </div>
                    </div>
                    <div className="row bottom-cards">
                        <div className="col-md-4 mt-4">
                            <div className="card text-center shadow rounded-4 h-100">
                                <div className="card-body">
                                    <div className="">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i></span>
                                    </div>
                                    <p className="sub-heading mb-0">Address</p>
                                    <a href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6" target="_blank" className="mb-0">Jaya Nagar 1st Block, 4/1, 2nd floor, Deviah Court, 22nd Cross Rd, Jayanagar 3rd Block, Bengaluru, Karnataka 560011</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4">
                            <div className="card text-center shadow rounded-4 h-100">
                                <div className="card-body">
                                    <div className="">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i></span>
                                    </div>
                                    <p className="sub-heading mb-0">Call Us</p>
                                    <a href="tel:+91 9141133678" className="mb-0 text-muted" target="_blank">+91 9141133678</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-4">
                            <div className="card text-center shadow rounded-4 h-100">
                                <div className="card-body">
                                    <div className="">
                                        <span><i className="fa fa-envelope"></i></span>
                                    </div>
                                    <p className="sub-heading mb-0">Email</p>
                                    <a href="mailto:namaste@thescalestory.com" className="mb-0 text-muted" target="_blank">namaste@thescalestory.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Address
