import React from 'react'
import './googlemap.css'
const MapViewer = () => {
    return (
      <div className="map-container">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/d/u/0/embed?mid=1upUhDuUclr0PoBf4FYohDzle89789bQ"
          style={{ height:'400px', width:'100%' }}
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    );
  };

const GoogleMap = () => {
  return (
    <>
      <div className="container-fluid pt-5 bg3 sec-google-map">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-3 text-center">
                            <p>We are seen</p>
                        </div>
                        <div className="col-md-6 text-center px-0">
                            <MapViewer />
                        </div>
                        <div className="col-md-3 text-center mt-3">
                            <p>across Bangalore</p>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default GoogleMap
