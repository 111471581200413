import React from 'react'
import { HashRouter as Router, Routes, Route, HashRouter } from "react-router-dom"
import './App.css'
import Home from './pages/home/Home'
import Footer from './components/common/footer/Footer'
import Header from './components/common/header/Header'
import AboutPage from './pages/about/AboutPage'
import Services from './pages/services/Services'
import ContactUs from './pages/contactus/ContactUs'
import PlaylistVideo from './pages/playlist/PlaylistVideo'
import ScrollToTop from './ScrollToTop'

const App = () => {
  return (
    <>
    <HashRouter>
      <ScrollToTop/>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<AboutPage/>} />
        <Route path='/service' element={<Services/>} />
        <Route path='/contact' element={<ContactUs/>} />
        <Route path='/playlist' element={<PlaylistVideo/>} />
      </Routes>
      <Footer/>
    </HashRouter>
    </>
  )
}

export default App
