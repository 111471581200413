import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <>
      <div className="container-fluid section-footer">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-md-5">
            <a href="#"><img src={process.env.PUBLIC_URL + '/assets/img/common/footerLogo.png'}  alt="Footer Logo" className='footer-logo' /> </a>
            
              <p className='footer-logo-detail'>The Scale Story is an advertising agency specializing in strategic marketing campaigns that help businesses scale. They offer services such as brand development, digital advertising, and social media marketing to drive growth and maximize results.</p>
            </div>
            <div className="col-md-3">
              <p className='footer-title'>Quick Links</p>
              <div className=''>
                <p className=''>
                  <a href="" className='footer-links d-block'>Home</a>
                  <a href="" className='footer-links d-block'>About</a>
                  <a href="" className='footer-links d-block'>Services</a>
                  <a href="" className='footer-links d-block'>Blog</a>
                  <a href="" className='footer-links d-block'>Contact</a>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <p className='footer-title'>Get In Touch</p>
              <div className='d-flex mb-3'>
                <div>
                  <i className="fa fa-phone fa-fa-icon" aria-hidden="true"></i>
                </div>
                <div className='ms-3'>
                  <a href="tel:+919141133678" target='_blank' className='footer-links'>+91 9141133678</a>
                </div>
              </div>
              <div className='d-flex mb-3'>
                <div>
                <i className="fa fa-envelope fa-fa-icon" aria-hidden="true"></i>
                </div>
                <div className='ms-3'>
                <a href="mailto:namaste@thescalestory.com" target='_blank' className='footer-links'>namaste@thescalestory.com</a>
                </div>
              </div>
              <div className='d-flex mb-3'>
                <div>
                <i className="fa fa-map-marker fa-fa-icon" aria-hidden="true"></i>
                </div>
                <div className='ms-3'>
                <a href="https://shorturl.at/oqDH3" target='_blank' className='footer-links'>4/1, 2nd floor, Deviah Court, 22nd Cross Rd, Jayanagar 3rd Block, Bengaluru, Karnataka 560011</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-black py-2 section-footer-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 footer-social-icon-mob-view">
              <a href="https://www.instagram.com/thescalestory" className='footer-social-icon ' target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a>
              <a href="https://www.facebook.com/thescalestory" className='footer-social-icon ps-3' target='_blank'><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
              <a href="https://www.facebook.com/thescalestory" className='footer-social-icon ps-3' target='_blank'><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
              <a href="https://www.facebook.com/thescalestory" className='footer-social-icon ps-3' target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a>
            </div>
            <div className="col-md-7">
              <p className='bottom-footer mb-0'>Copyright © 2024 Design And Developed By Phoenix Branding | All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer